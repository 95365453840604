<template>
  <div class="footBigBox">
    <div class="footBigBoxInner">
      <div class="footBigBoxInnerLeft">
        <div class="footBigBoxInnerLeftOne">
          <div class="footBigBoxInnerLeftOneTitle">公司产品</div>
          <div
            class="footBigBoxInnerLeftOneListOne"
            @click="goConvenienceStore"
          >
            大江便利店
          </div>
          <div class="footBigBoxInnerLeftOneListOne" @click="joinInvestment">
            招商加盟
          </div>
          <div class="footBigBoxInnerLeftOneListOne" @click="goStoreShow">
            门店展示
          </div>
        </div>
        <div class="footBigBoxInnerLeftOne two">
          <div class="footBigBoxInnerLeftOneTitle">关于我们</div>
          <div class="footBigBoxInnerLeftOneListOne" @click="goAboutUs">
            关于我们
          </div>
          <div class="footBigBoxInnerLeftOneListOne" @click="goNews">
            企业资讯
          </div>
        </div>
        <!-- 服务地址 -->
        <div class="footBigBoxInnerLeftAddress">
          <div class="fuwuTitle">服务热线</div>
          <div class="fuwuTitlePhone">400-105-6888</div>
          <!-- <div class="fuwuTitle">招商合作</div> -->
          <!-- 电子邮件 -->
          <!-- <div class="fuwuTitleEmail">marketing@dajiang.com</div> -->
          <div class="fuwuTitle">联系地址</div>
          <div class="fuwuTitleAddress">
            河北省保定市竞秀区向阳北大街2599号云中心3号楼1楼
          </div>
        </div>
      </div>
      <div class="footBigBoxInnerRight">
        <!-- 关于我们 -->
        <div class="guanzhuwomenTitle">关注我们</div>
        <div class="guanzhuQrcodeBox">
          <div class="guanzhuQrcodeBoxOne">
            <div class="guanzhuQrcodeBoxOneImgBox">
              <img
                src="https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/yasuo/weixin.jpg"
                alt=""
              />
            </div>
            企业公众号
          </div>
          <div class="guanzhuQrcodeBoxOne">
            <div class="guanzhuQrcodeBoxOneImgBox">
              <img
                src="https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/yasuo/douyin.png"
                alt=""
              />
            </div>
            企业抖音号
          </div>
        </div>
      </div>
    </div>
    <div class="beian">
      <p class="beianOne">Copyright 2024@ 河北米创电子科技有限公司版权所有</p>
      <p>ICP备案号：冀ICP备2023004810号-1</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 跳转到便利店页面
    goConvenienceStore() {
      this.$router.push("/djConvenienceStore");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    // 跳转到加盟页面
    joinInvestment() {
      this.$router.push("/joinInvestment");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    // 跳转门店展示页面
    goStoreShow() {
      this.$router.push("/storeShow");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    // 跳转到资讯页面
    goNews() {
      this.$router.push("/newsList");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    // 跳转到关于我们页面
    goAboutUs() {
      this.$router.push("/aboutUs");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.footBigBox {
  width: 100%;
  height: 23.5rem;
  background: #303030;
  .footBigBoxInner {
    width: 75rem;
    height: 17.25rem;
    margin: 0 auto;
    padding-top: 1.87rem;
    display: flex;
    .footBigBoxInnerLeft {
      display: flex;
      .footBigBoxInnerLeftOne {
        .footBigBoxInnerLeftOneTitle {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 0.94rem;
          color: #ffffff;
          margin-bottom: 1.25rem;
        }
        .footBigBoxInnerLeftOneListOne {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 0.94rem;
          color: rgba(255, 255, 255, 0.6);
          font-style: normal;
          margin-bottom: 1.25rem;
          cursor: pointer;
        }
      }
      .two {
        margin-left: 5.3125rem;
      }
      .footBigBoxInnerLeftAddress {
        margin-left: 6.5rem;
        .fuwuTitle {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 0.9375rem;
          color: rgba(255, 255, 255, 0.6);
        }
        .fuwuTitlePhone {
          font-family: Arial, Arial;
          font-weight: 900;
          font-size: 1.75rem;
          color: #ffffff;
          margin-top: 0.125rem;
          margin-bottom: 1.25rem;
        }
        .fuwuTitleEmail {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 0.9375rem;
          color: #ffffff;
          margin-bottom: 1.25rem;
          margin-top: 0.5rem;
        }
        .fuwuTitleAddress {
          margin-top: 0.5rem;
          width: 13.125rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 0.9375rem;
          color: #ffffff;
        }
      }
    }
    .footBigBoxInnerRight {
      margin-left: 12.3125rem;
      .guanzhuwomenTitle {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.875rem;
        color: rgba(255, 255, 255, 0.7);
      }
      .guanzhuQrcodeBox {
        margin-top: 1.875rem;
        display: flex;
        align-items: center;
        .guanzhuQrcodeBoxOne {
          margin-right: 2.5rem;
          text-align: center;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 0.9375rem;
          color: #ffffff;
          .guanzhuQrcodeBoxOneImgBox {
            width: 6.5rem;
            height: 6.5rem;
            padding: 0.5rem;
            background: #ffffff;
            border-radius: 0.625rem;
            margin-bottom: 0.625rem;
            img {
              width: 6.5rem;
              height: 6.5rem;
            }
          }
        }
      }
    }
  }
  .beian {
    border-top: 0.0625rem solid rgba(255, 255, 255, 0.27);
    width: 100%;
    height: 4.375rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 0.9375rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      cursor: pointer;
    }
    .beianOne {
      margin-right: 1.875rem;
    }
  }
}
</style>
