<template>
  <div class="rightFlexd" ref="rightFlexd">
    <div class="weixinBox">
      <img
        :src="wxicon"
        alt=""
        @click="showBox('weixin')"
        @mouseover="handleMouseOver('wxicon')"
        @mouseleave="handleMouseLeave('wxicon')"
      />
    </div>
    <div class="weixinBox">
      <img
        :src="phone"
        alt=""
        @click="showBox('phone')"
        @mouseover="handleMouseOver('phone')"
        @mouseleave="handleMouseLeave('phone')"
      />
    </div>
    <div class="weixinBox" v-if="isShowButton">
      <!-- 返回顶部按钮 -->
      <img
        src="https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/goTop.png"
        alt="返回顶部"
        @click="scrollToTop"
      />
    </div>

    <!-- 微信大框框 -->
    <div v-if="showWeixinBox" class="weixinBigBox">
      <div class="weixinBigBoxTitle">微信咨询</div>
      <div class="weixinBigBoxContent">开店专家1V1提供开店建议</div>
      <img
        src="https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/weixinQrcode.png"
        alt=""
      />
      <div class="weixinBigBoxBottom">手机扫码加我微信</div>
    </div>

    <!-- 电话咨询大框框 -->
    <div v-if="showPhoneBox" class="phoneBigBox">
      <div class="weixinBigBoxTitle">加盟咨询</div>
      <div class="weixinBigBoxContent">招商经理提供1V1开店方案</div>
      <input
        type="text"
        placeholder="请输入姓名"
        class="name"
        @input="iptName"
        :value="name"
      />
      <input
        type="text"
        placeholder="请输入电话"
        class="name phone"
        @input="iptMobile"
        maxlength="11"
        v-model="mobile"
      />
      <div class="lijizixun" @click="feedbackAdd">立即咨询</div>
      <div class="phoneBigBoxBottom">
        <img
          src="https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/phoneIcon.png"
          alt=""
        />
        <div class="phoneBigBoxBottomRight">
          <div class="phoneBigBoxBottomRightTitle">电话咨询（8:30-17:30）</div>
          <div class="phoneBigBoxBottomRightPhoneNum">400-105-6888</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wxicon:
        "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/wxicon.png",
      phone:
        "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/phone.png",
      showWeixinBox: false,
      showPhoneBox: false,
      clicked: {
        wxicon: false,
        phone: false,
      },
      isShowButton: false, // 控制按钮是否显示
      name: "",
      mobile: "",
    };
  },
  methods: {
    showBox(type) {
      if (type === "weixin") {
        this.showWeixinBox = true;
        this.showPhoneBox = false;
        this.wxicon =
          "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/weixinHaver.png";
        this.phone =
          "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/phone.png";
        this.clicked = { wxicon: true, phone: false };
      } else if (type === "phone") {
        this.showPhoneBox = true;
        this.showWeixinBox = false;
        this.phone =
          "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/phoneHaver.png";
        this.wxicon =
          "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/wxicon.png";
        this.clicked = { wxicon: false, phone: true };
      }
    },
    handleMouseOver(type) {
      if (!this.clicked[type]) {
        if (type === "wxicon") {
          this.wxicon =
            "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/weixinHaver.png";
        } else if (type === "phone") {
          this.phone =
            "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/phoneHaver.png";
        }
      }
    },
    handleMouseLeave(type) {
      if (!this.clicked[type]) {
        if (type === "wxicon") {
          this.wxicon =
            "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/wxicon.png";
        } else if (type === "phone") {
          this.phone =
            "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/phone.png";
        }
      }
    },
    resetState() {
      this.showWeixinBox = false;
      this.showPhoneBox = false;
      this.wxicon =
        "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/wxicon.png";
      this.phone =
        "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/rightFlexd/phone.png";
      this.clicked = { wxicon: false, phone: false };
    },
    handleClickOutside(event) {
      if (!this.$refs.rightFlexd.contains(event.target)) {
        this.resetState();
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
    // 监听滚动事件
    onScroll() {
      // 页面滚动高度
      this.isShowButton = window.scrollY > 300; // 滚动超过300px显示按钮
    },

    // 表单提交
    iptName(e) {
      this.name = e.target.value;
    },
    iptMobile(e) {
      this.mobile = this.mobile.replace(/[^0-9]/g, "").slice(0, 11);
    },
    // 立即预约
    async feedbackAdd() {
      if (this.name == "") {
        this.$message.error("请输入姓名");
        return;
      }
      if (this.mobile.length == 11) {
        await this.$axios
          .post("https://webapi.mclandian.com/api/web/feedbackAdd", {
            name: this.name,
            mobile: this.mobile,
            type: 2,
          })
          .then((res) => {
            if (res.data.code === 20000) {
              // 成功处理
              this.$message({
                showClose: true,
                message: "提交成功",
                type: "success",
              });
              this.name = "";
              this.mobile = "";
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error("获取数据失败");
          });
      } else {
        this.$message.error("请输入正确的手机号");
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    // 页面加载后，监听滚动事件
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
    // 组件销毁时移除事件监听
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="less" scoped>
.rightFlexd {
  .weixinBox {
    margin-bottom: 1.25rem;
    cursor: pointer;
  }
  .weixinBigBox {
    width: 18.81rem;
    height: 21.13rem;
    background: linear-gradient(
      227deg,
      RGBA(222, 246, 234, 1) 0%,
      #ffffff 100%
    );
    box-shadow: 0rem 0rem 1.69rem 0rem rgba(0, 0, 0, 0.07);
    border-radius: 0.63rem;
    position: absolute;
    left: -20.06rem;
    top: 0rem;
    text-align: center;
    .weixinBigBoxTitle {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 1.25rem;
      color: #00bc59;
      margin-top: 1.63rem;
      margin-bottom: 0.31rem;
    }
    .weixinBigBoxContent {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 1rem;
      margin-bottom: 0.88rem;
      color: #000000;
    }
    img {
      width: 11.72rem;
      height: 11.56rem;
      margin-bottom: 1.25rem;
    }
    .weixinBigBoxBottom {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 0.75rem;
      color: #979797;
      line-height: 1.06rem;
      letter-spacing: 10px;
      font-style: normal;
    }
  }
  .phoneBigBox {
    width: 18.81rem;
    height: 21.13rem;
    background: linear-gradient(
      227deg,
      RGBA(222, 246, 234, 1) 0%,
      #ffffff 100%
    );
    box-shadow: 0rem 0rem 1.69rem 0rem rgba(0, 0, 0, 0.07);
    border-radius: 0.63rem;
    position: absolute;
    left: -20.06rem;
    top: 0rem;
    text-align: center;
    .weixinBigBoxTitle {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 1.25rem;
      color: #00bc59;
      margin-top: 1.63rem;
      margin-bottom: 0.31rem;
    }
    .weixinBigBoxContent {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 1rem;
      margin-bottom: 0.88rem;
      color: #000000;
    }
    .name {
      width: 14.74rem;
      height: 2.5rem;
      padding: 0rem 0.94rem;
      border-radius: 0.31rem;
      border: 0.06rem solid #dfdfdf;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 0.88rem;
      color: #666666;
    }
    .name::placeholder {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 0.88rem;
      color: #666666;
    }
    .name:focus {
      font-family: PingFangSC, PingFang SC;
      font-size: 0.9375rem;
      color: #006548;
      border-radius: 0.31rem;
      border: 0.06rem solid #00bc59;
      outline: none; /* 去除默认焦点轮廓 */
      transition: all 0.3s ease; /* 添加过渡效果 */
    }
    .phone {
      margin-top: 0.94rem;
      margin-bottom: 1.25rem;
    }
    .lijizixun {
      width: 16.25rem;
      height: 2.75rem;
      background: #00bc59;
      border-radius: 0.31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 1rem;
      color: #ffffff;
      text-align: center;
      line-height: 2.75rem;
      margin: 0 auto;
      margin-bottom: 1.25rem;
      cursor: pointer;
    }
    .phoneBigBoxBottom {
      width: 16.25rem;
      margin: 0 auto;
      display: flex;
      align-items: center;
      img {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.38rem;
      }
      .phoneBigBoxBottomRightTitle {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 0.75rem;
        color: #000000;
      }
      .phoneBigBoxBottomRightPhoneNum {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 1.13rem;
        color: #000000;
      }
    }
  }
}
</style>
