<template>
  <div class="greenYuyueBox">
    <div class="greenYuyueBoxCenter">
      <div class="greenYuyueBoxCenterLeft">
        <img
          src="https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/greenYuyueBoxIcon.png"
          alt=""
          class="greenLogo"
        />
        <div class="greenYuyueBoxCenterLeftText">
          <p class="xiangkai">想开便利店，限时扶持优惠等你来</p>
          <p class="tianxie">填写您的信息，招商经理将会尽快与您取得联系</p>
        </div>
      </div>
      <div class="greenYuyueBoxCenterRight">
        <input
          type="text"
          placeholder="请输入姓名"
          class="name"
          @input="iptName"
          :value="name"
        />
        <input
          type="text"
          placeholder="请输入电话"
          class="name phone"
          @input="iptMobile"
          maxlength="11"
          v-model="mobile"
        />
        <div class="lijiyuyue" @click="feedbackAdd">立即预约</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      mobile: "",
    };
  },
  methods: {
    iptName(e) {
      this.name = e.target.value;
    },
    iptMobile(e) {
      this.mobile = this.mobile.replace(/[^0-9]/g, "").slice(0, 11);
    },
    // 立即预约
    async feedbackAdd() {
      if (this.name == "") {
        this.$message.error("请输入姓名");
        return;
      }
      if (this.mobile.length == 11) {
        await this.$axios
          .post("https://webapi.mclandian.com/api/web/feedbackAdd", {
            name: this.name,
            mobile: this.mobile,
            type: 2,
          })
          .then((res) => {
            if (res.data.code === 20000) {
              // 成功处理
              this.$message({
                showClose: true,
                message: "提交成功",
                type: "success",
              });
              this.name = "";
              this.mobile = "";
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error("获取数据失败");
          });
      } else {
        this.$message.error("请输入正确的手机号");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.greenYuyueBox {
  width: 100%;
  height: 5.625rem;
  background: url("https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/greenYuyueBoxBack.png")
    no-repeat 100% 100%;
  .greenYuyueBoxCenter {
    margin: 0 auto;
    width: 75rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .greenYuyueBoxCenterLeft {
      display: flex;
      align-items: center;
      .greenLogo {
        width: 3.39rem;
        height: 3.38rem;
        border-radius: 0.625rem;
        margin-right: 0.625rem;
      }
      .xiangkai {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 1.5rem;
        color: #ffffff;
      }
      .tianxie {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1.125rem;
        color: #ffffff;
      }
    }
    .greenYuyueBoxCenterRight {
      width: 37.375rem;
      height: 3.625rem;
      background: #ffffff;
      border-radius: 0.625rem;
      display: flex;
      align-items: center;
      .name {
        width: 11.5rem;
        height: 1.3125rem;
        padding: 0.5625rem 1.0625rem;
        border-radius: 0.5rem;
        border: 0.0625rem solid #6acaae;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 0.9375rem;
        color: #006548;
        margin-left: 1.125rem;
      }
      .name::placeholder {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 0.9375rem;
        color: #7bd0b7;
      }
      .name:focus {
        font-family: PingFangSC, PingFang SC;
        font-size: 0.9375rem;
        color: #006548;
        border: 0.0625rem solid #006548;
        outline: none; /* 去除默认焦点轮廓 */
        transition: all 0.3s ease; /* 添加过渡效果 */
      }
      .phone {
        margin-left: 0.625rem;
      }
    }
    .lijiyuyue {
      width: 6.6875rem;
      height: 2.375rem;
      background: #006548;
      border-radius: 0.5rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 0.9375rem;
      color: #ffffff;
      text-align: center;
      line-height: 2.375rem;
      margin-left: 0.5rem;
      cursor: pointer;
    }
  }
}
</style>
