<template>
  <div>
    <!-- 顶部导航栏 -->
    <div :class="headerClass" @mouseover="changeImage" @mouseout="resetImage">
      <div class="headerCenter">
        <!-- logo -->
        <img :src="indexLogo" alt="" class="indexLogo" @click="goHome" />
        <!-- 顶部导航栏 -->
        <div class="topNavBox">
          <div class="topNavOne" @click="goHome">首页</div>
          <div class="topNavOne" @click="goConvenienceStore">大江便利店</div>
          <div class="topNavOne zhaoshang" @click="joinInvestment">
            招商加盟<img
              src="https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/huomiao.gif"
              alt="huomiao GIF"
              class="huomiao"
              style="width: 1rem; height: 1rem; margin-left: 2px"
            />
          </div>
          <div class="topNavOne" @click="goStoreShow">门店展示</div>
          <div class="topNavOne" @click="goNews">企业资讯</div>
          <div class="topNavOne" @click="goAboutUs">关于我们</div>
        </div>
        <!-- 加盟电话 -->
        <div class="joinPhoneBox">
          <img :src="joinIcon" alt="" class="joinIcon" />
          <p>400-105-6888</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      indexLogo:
        "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/header/indexLogo.png",
      joinIcon:
        "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/header/joinIcon.png",
      isScrolled: false, // 用于判断是否滚动
      isHovered: false, // 用于判断是否鼠标悬停
    };
  },
  computed: {
    headerClass() {
      // 计算头部的背景类，如果滚动了就使用other-header类
      if (this.isScrolled || this.$route.path !== "/home") {
        return "other-header";
      }
      return "header";
    },
    // 根据路由路径动态更新图片路径
    logoImage() {
      return this.$route.path === "/home"
        ? "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/header/indexLogo.png"
        : "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/header/indexLogoAct.png";
    },
    joinImage() {
      return this.$route.path === "/home"
        ? "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/header/joinIcon.png"
        : "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/header/joinIconAct.png";
    },
  },
  watch: {
    // 监听路由变化以便在路由切换时动态改变headerClass和图片路径
    "$route.path"() {
      this.resetImage();
    },
  },
  methods: {
    // 鼠标悬停时更改图片路径
    changeImage() {
      this.indexLogo =
        "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/header/indexLogoAct.png";
      this.joinIcon =
        "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/header/joinIconAct.png";
      this.isHovered = true;
    },
    // 鼠标移出时恢复原来的图片路径
    resetImage() {
      if (!this.isScrolled) {
        this.indexLogo = this.logoImage;
        this.joinIcon = this.joinImage;
      }
      this.isHovered = false;
    },
    // 页面滚动时触发
    onScroll() {
      this.isScrolled = window.scrollY > 0;
      // 如果滚动时，切换为鼠标悬停时的图片路径
      if (this.isScrolled) {
        this.indexLogo =
          "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/header/indexLogoAct.png";
        this.joinIcon =
          "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/header/joinIconAct.png";
      } else {
        this.indexLogo = this.logoImage;
        this.joinIcon = this.joinImage;
      }
    },
    // 跳转到首页
    goHome() {
      this.indexLogo =
        "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/header/indexLogoAct.png";
      this.joinIcon =
        "https://official-web-img.oss-cn-beijing.aliyuncs.com/assets/header/joinIconAct.png";
      this.$router.push("/home").then(() => {
        // 跳转后滚动到页面顶部
        window.scrollTo(0, 0);
      });
    },
    // 跳转到便利店页面
    goConvenienceStore() {
      this.$router.push("/djConvenienceStore").then(() => {
        window.scrollTo(0, 0);
      });
    },
    // 跳转到加盟页面
    joinInvestment() {
      this.$router.push("/joinInvestment").then(() => {
        window.scrollTo(0, 0);
      });
    },
    // 跳转门店展示页面
    goStoreShow() {
      this.$router.push("/storeShow").then(() => {
        window.scrollTo(0, 0);
      });
    },
    // 跳转到资讯页面
    goNews() {
      this.$router.push("/newsList").then(() => {
        window.scrollTo(0, 0);
      });
    },
    // 跳转到关于我们页面
    goAboutUs() {
      this.$router.push("/aboutUs").then(() => {
        window.scrollTo(0, 0);
      });
    },
  },
  mounted() {
    // 监听页面滚动
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    // 卸载滚动监听
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 6.125rem;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 1rem;
  color: #ffffff;
  position: relative;

  z-index: 2;
  .headerCenter {
    width: 75rem;
    height: 6.125rem;
    line-height: 6.125rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .indexLogo {
      width: 9.875rem;
      height: 1.6875rem;
      cursor: pointer;
    }
    .topNavBox {
      display: flex;
      align-items: center;
      .topNavOne {
        margin-right: 2.1875rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1rem;
        color: #ffffff;
        cursor: pointer;
      }
    }
    .joinPhoneBox {
      display: flex;
      align-items: center;
      .joinIcon {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.625rem;
      }
      font-family: Arial, Arial;
      font-weight: 900;
      font-size: 1.25rem;
      color: #ffffff;
    }
  }
}

.header:hover {
  background: #ffffff;
  .headerCenter {
    .topNavBox .topNavOne {
      color: #000000;
    }
    .joinPhoneBox {
      color: #000000;
    }
  }
}

.other-header {
  width: 100%;
  height: 6.125rem;
  box-shadow: 0 12px 10px -10px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 1rem;
  color: #000000;
  position: relative;
  z-index: 2;
  .headerCenter {
    width: 75rem;
    height: 6.125rem;
    line-height: 6.125rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .indexLogo {
      width: 9.875rem;
      height: 1.6875rem;
      cursor: pointer;
    }
    .topNavBox {
      display: flex;
      align-items: center;
      .topNavOne {
        margin-right: 2.1875rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 1rem;
        color: #000000;
        cursor: pointer;
      }
    }
    .joinPhoneBox {
      display: flex;
      align-items: center;
      .joinIcon {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.625rem;
      }
      font-family: Arial, Arial;
      font-weight: 900;
      font-size: 1.25rem;
      color: #000000;
    }
  }
}
</style>
