import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import App from './App.vue'
// 引入axios
import Axios from 'axios'
// 引入vue-router
import VueRouter from 'vue-router'
import router from './router'
import "swiper/css/swiper.min.css";
Vue.use(VueRouter)
Vue.use(ElementUI)
Vue.config.productionTip = false
// 将axios挂载到Vue原型的原型上
Vue.prototype.$axios = Axios
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  if (to.meta.content) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  // /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
