<template>
  <div id="app">
    <!-- 顶部导航 -->
    <headerBox class="headerBox"></headerBox>
    <!-- <div class="top">我是顶部</div> -->
    <router-view />
    <!-- 绿色预约 -->
    <greenYuyueBox class="greenBox"></greenYuyueBox>
    <!-- 底部黑块导航 -->
    <bottomBox></bottomBox>
    <!-- 右侧悬浮 -->
    <rightFlexd class="rightFlexdBox"></rightFlexd>
  </div>
</template>

<script>
import headerBox from "./components/herderBox.vue";
import bottomBox from "./components/bottomBox.vue";
import greenYuyueBox from "./components/greenYuyueBox.vue";
import rightFlexd from "./components/rightFlexd.vue";
export default {
  name: "App",
  components: { headerBox, bottomBox, greenYuyueBox, rightFlexd },
};
</script>

<style>
#app {
  padding-bottom: 90px;
}
.headerBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.top {
  height: 100px;
  width: 100%;
  background: plum;
}
.bottom {
  height: 200px;
  width: 100%;
  background: purple;
  text-align: center;
}
.greenBox {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 5;
}
.rightFlexdBox {
  position: fixed;
  right: 1.19rem;
  top: 22rem;
  z-index: 11;
}
</style>
