// 引入Vue核心库
import Vue from 'vue'
// 引入vue-router
import VueRouter from 'vue-router'
// 应用vue-router插件
Vue.use(VueRouter)
// 保存原始的 push 方法
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
            throw err;
        }
    });
};

// 配置路由
const routes = [
    { path: '', redirect: '/home' },
    {
        path: '/home', component: () => import('@/views/home.vue'), meta: {
            title: '大江控股集团官网_大江便利店_大江第三空间_大江工厂',
            content: {
                keywords: '大江控股集团官网,大江便利店,大江便利店官网,大江第三空间,大江鲜生',
                description: '这大江控股集团，是业界多元业务融合的领航者。旗下大江品牌，以便利店、第三空间及工厂为支柱，全面服务于消费者与企业客户。',
            },
        }
    },
    {
        path: '/djConvenienceStore', component: () => import('@/views/djConvenienceStore.vue'), meta: {
            title: '大江便利店_健康三餐便利店',
            content: {
                keywords: '大江控股集团官网,大江便利店,大江便利店官网,大江便利店加盟,大江便利店地址,大江便利店联系方式',
                description: '大江便利店（加盟热线：4001056888）密布城市脉络，从商务区到学区，从医疗中心到交通枢纽，乃至居民社区，无缝融入消费者日常。',
            },
        }
    },
    {
        path: '/joinInvestment', component: () => import('@/views/joinInvestment.vue'), meta: {
            title: '加盟合作｜大江健康三餐便利店',
            content: {
                keywords: '大江便利店,大江便利店加盟,便利店加盟,便利超市加盟,河北便利店加盟',
                description: '大江便利店（加盟热线：4001056888）密布城市脉络，从商务区到学区，从医疗中心到交通枢纽，乃至居民社区，无缝融入消费者日常。',
            },
        }
    },
    {
        path: '/storeShow', component: () => import('@/views/storeShow.vue'), meta: {
            title: '门店展示｜大江健康三餐便利店',
            content: {
                keywords: '大江便利店,大江便利店加盟,便利店加盟,便利超市加盟,河北便利店加盟',
                description: '大江便利店（加盟热线：4001056888）密布城市脉络，从商务区到学区，从医疗中心到交通枢纽，乃至居民社区，无缝融入消费者日常。',
            },
        }
    },
    {
        path: '/newsList', component: () => import('@/views/newsList.vue'), meta: {
            title: '企业资讯｜大江控股集团官网_大江便利店_大江第三空间_大江工厂',
            content: {
                keywords: '大江控股集团官网,大江便利店,大江便利店官网,大江第三空间,大江鲜生',
                description: '大江控股集团，是业界多元业务融合的领航者。旗下大江品牌，以便利店、第三空间及工厂为支柱，全面服务于消费者与企业客户。',
            },
        }
    },
    {
        path: '/newsDetail', component: () => import('@/views/newsDetail.vue'), meta: {
            title: '企业资讯｜大江控股集团官网_大江便利店_大江第三空间_大江工厂',
            content: {
                keywords: '大江控股集团官网,大江便利店,大江便利店官网,大江第三空间,大江鲜生',
                description: '大江控股集团，是业界多元业务融合的领航者。旗下大江品牌，以便利店、第三空间及工厂为支柱，全面服务于消费者与企业客户。',
            },
        }
    },
    {
        path: '/aboutUs', component: () => import('@/views/aboutUs.vue'), meta: {
            title: '关于我们｜大江控股集团官网_大江便利店_大江第三空间_大江工厂',
            content: {
                keywords: '大江控股集团官网,大江便利店,大江便利店官网,大江第三空间,大江鲜生',
                description: '大江控股集团，是业界多元业务融合的领航者。旗下大江品牌，以便利店、第三空间及工厂为支柱，全面服务于消费者与企业客户。',
            },
        }
    },
]

// 创建路由器
const router = new VueRouter({
    routes
})

// 导出路由器
export default router